import styles from './style.module.css';

const HealthCustomCard = ({ children, noPadding }) => {

  return (
    <div className={!noPadding ? styles.cardContainer : styles.noPadding}>
     { children }
    </div>
  );
};

export default HealthCustomCard;