import RegisterContainer from '../../../Container/Dashboard/Register';

const Register = () => {

	return (
		<RegisterContainer />
	)
}

export default Register;
