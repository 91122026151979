import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import HealthCustomCard from '../../../../Components/HealthCustomCard';
import { updateProduct } from '../../../../services/kollectaHealthCore';
import editIcon from './edit.svg';
import modalRed from '../redModalIcon.svg';
import modalGreen from '../greenModalIcon.svg';
import * as moment from 'moment';
import Modal from '../../../../Components/Modal';
import CustomDatePicker from '../../../../Components/DatePicker';
import { usePatient } from '../../../../Contexts/PatientContext';

const DetailsCard = () => {
  const { patientData, updatePatient } = usePatient();
  const [product, setProduct] = useState({ normalItems: [], uniqueRowItem: null });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [editData, setEditData] = useState({ field: '', label: '', fieldKey: '', type: '' });

  useEffect(() => {
    if (patientData) {
      const normalItems = [
        {
          label: 'Data do Diagnóstico',
          field: moment(patientData.diagnosticAt).format('DD/MM/YYYY'),
          editable: true,
          type: 'date',
          fieldKey: 'diagnosticAt'
        },
        {
          label: 'CID',
          field: patientData.cid,
          editable: true,
          type: 'text',
          fieldKey: 'cid'
        },
        {
          label: 'Estadiamento AJCC',
          field: patientData.ajcc,
          editable: true,
          type: 'text',
          fieldKey: 'ajcc'
        },
        {
          label: 'Comorbidades',
          field: patientData.comorbidities,
          editable: true,
          type: 'text',
          fieldKey: 'comorbidities'
        },
        {
          label: 'Alergias',
          field: patientData.allergies,
          editable: true,
          type: 'text',
          fieldKey: 'allergies'
        },
        {
          label: 'ECOG',
          field: patientData.ecog,
          editable: true,
          type: 'text',
          fieldKey: 'ecog'
        }
      ];

      const uniqueRowItem = {
        label: 'Características Moleculares',
        field: patientData.molecularCharacteristics,
        editable: true,
        type: 'text',
        fieldKey: 'molecularCharacteristics'
      };

      setProduct({ normalItems, uniqueRowItem });
    }
  }, [patientData]);

  const handleEdit = (item) => {
    setEditData(item);
    setEditModalOpen(true);
  };

  const handleSaveEdit = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmSave = async () => {
    try {
      let dataToUpdate = { [editData.fieldKey]: editData.field };

      if (editData.type === 'date' && editData.field) {
        dataToUpdate = { [editData.fieldKey]: moment(editData.field, 'DD/MM/YYYY').toISOString() };
      }

      await updateProduct(patientData.id, dataToUpdate);
      updatePatient({ ...patientData, ...dataToUpdate });

      setSuccessModal(true);
    } catch (error) {
      console.error('Failed to update product:', error);
      setErrorModal(true);
    } finally {
      setConfirmModalOpen(false);
      setEditModalOpen(false);
    }
  };

  useEffect(() => {
    if (editModalOpen) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [editModalOpen]);

  const renderEditInput = () => {
    if (editData.type === 'date') {
      return (
        <CustomDatePicker
          selectedDate={moment(editData.field, 'DD/MM/YYYY').toDate()}
          onDateChange={(date) => setEditData({ ...editData, field: moment(date).format('DD/MM/YYYY') })}
          minDateEnabled={false}
        />
      );
    }
    return (
      <input
        type="text"
        value={editData.field}
        onChange={(e) => setEditData({ ...editData, field: e.target.value })}
      />
    );
  };

  return (
    <HealthCustomCard noPadding>
      <section className={styles.wrapper}>
        <div className={styles.detailCard}>
          {product.normalItems.map((item, index) => (
            <div className={styles.detailCardItem} key={index}>
              <h1>{item.label}</h1>
              <div className={styles.editContainer}>
                <p>{item.field}</p>
                {item.editable && (
                  <img
                    src={editIcon}
                    alt="Edit"
                    className={styles.editIcon}
                    onClick={() => handleEdit(item)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        {product.uniqueRowItem && (
          <div className={styles.uniqueRow}>
            <h1>{product.uniqueRowItem.label}</h1>
            <div className={styles.editContainer}>
              <p>{product.uniqueRowItem.field}</p>
              {product.uniqueRowItem.editable && (
                <img
                  src={editIcon}
                  alt="Edit"
                  className={styles.editIcon}
                  onClick={() => handleEdit(product.uniqueRowItem)}
                />
              )}
            </div>
          </div>
        )}
        {editModalOpen && (
          <Modal onClose={() => setEditModalOpen(false)}>
            <div className={styles.containerModal}>
              <h2>Editar {editData.label}</h2>
              {renderEditInput()}
              <div className={styles.buttonsContainer}>
                <button onClick={handleSaveEdit} className={styles.buttonSuccess}>Continuar</button>
              </div>
            </div>
          </Modal>
        )}
        {confirmModalOpen && (
          <Modal onClose={() => setConfirmModalOpen(false)}>
            <div className={styles.containerModal}>
              <img src={modalRed} alt="Red Modal Icon" />
              <p>Tem certeza que deseja continuar com a alteração da doença?</p>
              <div className={styles.buttonsContainer}>
                <button onClick={handleConfirmSave} className={styles.buttonSuccess}>Continuar</button>
                <button onClick={() => setConfirmModalOpen(false)} className={styles.buttonError}>Cancelar</button>
              </div>
            </div>
          </Modal>
        )}
        {successModal && (
          <Modal onClose={() => setSuccessModal(false)}>
            <div className={styles.containerModal}>
              <img src={modalGreen} alt="Green Modal Icon" />
              <h2>Doença Alterada</h2>
            </div>
          </Modal>
        )}
        {errorModal && (
          <Modal onClose={() => setErrorModal(false)}>
            <div className={styles.containerModal}>
              <img src={modalRed} alt="Red Modal Icon" />
              <h2>Erro ao alterar Doença</h2>
              <p>Tente novamente</p>
            </div>
          </Modal>
        )}
      </section>
    </HealthCustomCard>
  );
};

export default DetailsCard;
