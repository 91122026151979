import React, { useState } from "react";
import { useEffect } from "react";
import styles from './style.module.css';
import { IoIosClose } from "react-icons/io";
import { exportCustomersByAnswers } from "../../services/kollectaHealthCore";

const ReportFilterTool = ({ closeModal, setFeedback }) => {
  const statusSettings = {
    // viewed: {
    //   label: "Pacientes aderentes ao programa",
    // }, 
    leve: {
      label: "Pacientes que apresentaram sintomas leves",
    },
    moderada: {
      label: "Pacientes que apresentaram sintomas moderados",
    },
    grave: {
      label: "Pacientes que apresentaram sintomas graves",
    },
    todos: {
      label: "Total de pacientes que apresentaram algum sintoma",
    },
    "Hospital IGESP": {
      label: "Pacientes que foram encaminhados ao P.S",
    },
  }

  const [selectedStatuses, setSelectedStatuses] = useState(
    Object.keys(statusSettings).reduce((acc, key) => ({ ...acc, [key]: false }), {})
  );

  const handleStatusChange = (statusKey) => {
    setSelectedStatuses(prevSelectedStatuses => ({
      ...prevSelectedStatuses,
      [statusKey]: !prevSelectedStatuses[statusKey]
    }));
  };

  const handleExport = async () => {
    setFeedback('isLoading');
  
    let selectedStatusKeys = Object.keys(selectedStatuses).filter(key => selectedStatuses[key]);
  
    // Se 'todos' está selecionado, substitui apenas os filtros de sintomas
    if (selectedStatusKeys.includes('todos')) {
      // Remove os filtros de sintomas existentes e 'todos' da lista
      selectedStatusKeys = selectedStatusKeys.filter(key => key !== 'leve' && key !== 'moderada' && key !== 'grave' && key !== 'todos');
      // Adiciona os três níveis de sintomas
      selectedStatusKeys.push('leve', 'moderada', 'grave');
    }
  
    try {
      const blob = await exportCustomersByAnswers(selectedStatusKeys);
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = 'relatorio.xlsx';
      document.body.appendChild(a);
      a.click();
  
      URL.revokeObjectURL(downloadUrl);
      a.remove();
      setFeedback('isSuccess');
    } catch (error) {
      setFeedback('isError');
      console.error("There was an error exporting the report:", error);
    }
  };  

  useEffect(() => {
  }, [selectedStatuses]);


  return (
    <section className={styles.wrapper}>
      <div className={styles.top}>
       <h2>Escolha as informações do seu relatório</h2>
       <button onClick={() => closeModal(false)} className={styles.close}><IoIosClose /></button>
      </div>
      
      <div className={styles.container}>
          <div className={styles.subContainer}>
            <div className={styles.radiosContainer}>
              {Object.entries(statusSettings).map(([key, { label }]) => (
              <div key={key} className={styles.radioItem}>
                <input 
                  type="checkbox" 
                  id={key} 
                  name="status"
                  className={styles.customCheckbox}
                  checked={selectedStatuses[key]}
                  onChange={() => handleStatusChange(key)}
                />
                <div className={styles.radioTexts}>
                  <label htmlFor={key}>{label}</label>
                </div>
              </div>
             ))}
            </div>
          </div>
      </div>
      <button onClick={handleExport} className={styles.generalButton}>Exportar Relatório</button>

    </section>
  );
};

export default ReportFilterTool;
