import React, { useEffect, useState } from 'react';
import * as moment from 'moment';
import styles from './style.module.css';
import { getBotHistoryByPersonalNumber, exportBotHistoryPDF } from '../../../services/kollectaHealthCore';
import ClockIcon from './clock.svg';
import CalendarIcon from './calendar.svg';
import { usePatient } from '../../../Contexts/PatientContext';
import HealthHeader from '../../../Components/HealthHeader';
import ProfileImage from './profile.svg';
import HealthCustomCard from '../../../Components/HealthCustomCard';
import HistoryRadios from '../../../Components/HistoryRadios';
import { useNavigate } from 'react-router-dom';
import DateRangeSelector from '../../../Components/Charts/DateRangeSelector';
import Modal from '../../../Components/Modal';

const BotHistory = () => {
  const { patientData } = usePatient();
  const [botHistory, setBotHistory] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(3, 'months').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggleDatePicker = () => setIsOpen(!isOpen);

  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      fetchBotHistory(patientData?.customer?.personalNumber, start, end);
    }
  };

  const statusLabels = {
    'imported': 'Ativo',
    'obito_por_cancer': 'Óbito por câncer',
    'obito_por_outra_causa': 'Óbito por outra causa',
    'obito_desconhecido': 'Óbito desconhecido',
    'saida_do_estudo_por_opcao': 'Saída do estudo por opção',
  };

  const fetchBotHistory = async (personalNumber, startDate, endDate) => {
    try {
      const history = await getBotHistoryByPersonalNumber(
        personalNumber, 
        moment(startDate).format('YYYY-MM-DD'), 
        moment(endDate).format('YYYY-MM-DD')
      );
      setBotHistory(history?.data);
    } catch (error) {
      console.error('Failed to fetch bot history:', error);
    }
  };

  const exportPDF = async () => {
    if (!patientData?.customer?.personalNumber) {
      console.error('Número pessoal não disponível.');
      return;
    }
  
    try {
      const pdfBlob = await exportBotHistoryPDF(patientData.customer.personalNumber);
      const url = window.URL.createObjectURL(new Blob([pdfBlob], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `bot-history-${patientData.customer.personalNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Erro ao exportar o histórico do bot em PDF:', error);
      alert('Falha ao exportar PDF.');
    }
  };

  const validResponses = ["nenhuma", "leve", "moderada", "grave", "muito grave"];

  const renderResponseContent = (answer) => {
    if (answer) {
      if (validResponses.includes(answer.toLowerCase())) {
        return <HistoryRadios selectedResponse={answer} />;
      } else {
        return <span>{answer || 'Sem resposta'}</span>;
      }
    }
  };

  const goBack = () => {
    navigate('/dashboard/paciente');
  };

  useEffect(() => {
    if (patientData?.customer?.personalNumber) {
      fetchBotHistory(patientData.customer.personalNumber, startDate, endDate);
    }
  }, [patientData]);

  return (
    <>
    <HealthCustomCard>
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <HealthHeader title="Histórico de Respostas" />
      </div>

      <div className={styles.historyContainer}>
        <div className={styles.historyCardTop}>
            <a className={styles.link} onClick={() => goBack()}> &lt; Voltar para o perfil do paciente </a>
        </div>

        <div className={styles.profileCardLeft}>
            <div className={styles.profileCardLeftTop}>
              <img src={ProfileImage} alt="Kollecta Health"/>
            </div>

            <div className={styles.profileCardLeftInfos}>
              <h1>{patientData?.customer?.name}</h1>
              <div className={styles.profileCardLeftContent}>
                <p>
                  <span>
                    Data do Estudo Atual
                  </span>
                  {patientData?.createdAt && moment(patientData?.createdAt).format('DD/MM/YYYY')}
                </p>

                <p className={`${patientData?.status === 'imported' ? styles.statusActive : styles.statusInactive}`}>
                  <span>
                    Condição do Tratamento
                  </span>
                  {statusLabels[patientData?.status] || '-'}
                </p>

                <p>
                  <span>
                    Tratamento atual
                  </span>
                  {patientData?.treatments && patientData?.treatments[patientData?.treatments?.length -1]?.currentProtocol}
                </p>
              </div>
            </div>
            <div className={styles.containerButton}>
              <button onClick={toggleDatePicker} className={styles.generalButton}>Filtrar por Data</button>
              <button onClick={exportPDF} className={styles.generalButton}>Exportar PDF</button>

            </div>
        </div>

        <div className={styles.results}>
          {botHistory.length > 0 ? (
            botHistory.map((history, index) => (
              <div key={index} className={styles.historyItem}>
                <div className={styles.historyItemTop}>
                  <div className={styles.details}>
                    <img src={CalendarIcon} alt="Calendário Icone" />
                    <span>{moment(history?.createdAt).format('DD/MM/YYYY')}</span>
                    <img src={ClockIcon} alt="Relógio Icone" className={styles.marginLeft}/>
                    <span>{moment(history?.createdAt).format('HH:mm')}</span>
                  </div>
                </div>
                <p><b>{history.question}</b></p>
                <p>{renderResponseContent(history.answer)}</p>
              </div>
            ))
          ) : (
            <p>Sem histórico disponível</p>
          )}
        </div>
      </div>

      {isOpen && (
        <Modal onClose={() => setIsOpen(prev => (false))}>
          <div className={styles.datepickerWrapper}>
            <p> Selecione o perído </p>
            <DateRangeSelector 
                onDateChange={handleDateChange} 
                startDate={startDate}
                endDate={endDate}
            /> 
            <button className={styles.filter} onClick={() => setIsOpen(prev => (false))}> Filtrar Respostas </button>
          </div>
        </Modal>
      )}
      
    </div>
    </HealthCustomCard>
</>  );
};

export default BotHistory;
