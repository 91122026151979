import React from 'react';
import ProductInternal from '../../../Container/Dashboard/ProductInternal';

const ProductDetails = () => {
	return (
		<ProductInternal />
	)
}

export default ProductDetails;
