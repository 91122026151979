import React from 'react'
import styles from './style.module.css'
import { UploadFileAndList } from '../../../Components'
import exampleFile from './example-health.xlsx'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import HealthHeader from '../../../Components/HealthHeader';
import LeftIcon from './leftIcon.svg';

const Register = () => {
  return (
    <div className={styles.container}>
      <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
        <section>
          <div className={styles.top}>
            <HealthHeader title="Cadastro de Pacientes"/>
          </div>     

          <div className={styles.subtitle}>
            <h3> Upload de arquivos </h3>
            <img src={LeftIcon} alt="Kollecta Health"/>
            <p>Faça o upload do arquivo em Excel com as informações dos pacientes que ficarão disponiveis no “Perfil do Paciente”.</p>
            <p> Caso tenha dúvidas sobre o formato do arquivo, <a target="_blank" rel="noreferrer" href={exampleFile}>clique neste link</a> onde terá acesso ao modelo. </p>
          </div>

          <div  className={styles.paddingContent}>
            <div className={styles.uploadDocument}>
              <div className={styles.uploadDocumentContent}>
                <UploadFileAndList showList />
              </div>
            </div>
          </div>
        </section>
      </ReactCSSTransitionGroup>
    </div>
  )
}

export default Register;
