import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.kollecta.health',
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');

      console.log('Sessão expirada ou token inválido, redirecionando para login...');
      window.location.href = '/login';

      return Promise.reject({
        success: false,
        statusCode: 401,
        message: 'Sessão expirada ou token inválido. Por favor, faça login novamente.'
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
