import HomeContainer from '../../../Container/Dashboard/Home';

const Home = () => {

	return (
		<HomeContainer />
	)
}

export default Home;
