import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import ReactGA from 'react-ga';

import styles from './style.module.css';
import Logo from '../../assets/logos/igespBackground.svg';
import SmallLogo from '../../assets/logos/smallLogo.svg';

import homeIcon from './icons/home.svg';
import searchIcon from './icons/search.svg';
import listIcon from './icons/list.svg';

const Layout = ({
  children
}) => {
  const { pathname } = useLocation();
  let navigate = useNavigate();
  const [hovered, setHovered] = useState(null);

  useEffect(() => {
  }, []);

  const handleMenuClick = (label, path) => {
    ReactGA.event({
      category: 'Menu',
      action: 'Clique',
      label: label
    });
    navigate(path);
  };

  const menuItems = [
    { icon: homeIcon, label: 'Indicadores pacientes', path: "/dashboard/home" },
    { icon: searchIcon, label: 'Buscar pacientes', path: "/dashboard/pesquisa" },
    { icon: listIcon, label: 'Cadastro de pacientes', path: "/dashboard/cadastro" }
  ];

  const Dash = () => (
    <section className={styles.layoutDash}>
      <div className={hovered ? `${styles.sidebar} ${styles.sidebarExpanded}` : styles.sidebar}      
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(null)}
      >

      <div className={hovered ? styles.dashLogo : `${styles.dashLogo} ${styles.smallLogo}`}>
        <img 
          src={hovered ? Logo : SmallLogo} 
          className={styles.dashLogo} 
          alt="Kollecta Health" 
        />
      </div>
      
      <ul className={styles.menuDash}>
        {menuItems.map((item, index) => (
          <li
            key={index}
            onClick={() => handleMenuClick(item.label, item.path)}
            className={classNames(styles.menuItem, {
              [styles.menuItemActivated]: pathname === item.path,
              [styles.hover]: hovered === index
            })}
          >
            <img src={item.icon} alt={item.label} className={styles.icon} />
            { hovered ? <span>{item.label}</span> : ''}
          </li>
        ))}
      </ul>
      </div>
      <div className={styles.body}>
        {children}
      </div>
    </section>
  );

  return <Dash />;
};

export default Layout;
