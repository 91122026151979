import React, { useEffect, useState } from 'react';
import { usePatient } from '../../../../Contexts/PatientContext';
import styles from './style.module.css';
import LeftIcon from '../leftIcon.svg';
import HealthCustomCard from '../../../../Components/HealthCustomCard';
import ProfileImage from '../images/profile.svg';
import * as moment from 'moment';
import Modal from '../../../../Components/Modal';
import { createTreatment, updateProduct, upsertCustomer } from '../../../../services/kollectaHealthCore'; // Importando upsertCustomer
import Papa from 'papaparse';
import Select from 'react-select';
import modalRed from '../redModalIcon.svg';
import modalGreen from '../greenModalIcon.svg';
import editIcon from './edit.svg';

const Profile = () => {
  const { patientData, addTreatment, updatePatient } = usePatient();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [genderModalOpen, setGenderModalOpen] = useState(false); // Novo estado para abrir modal de gênero
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [drugList, setDrugList] = useState([]);
  const [editData, setEditData] = useState({
    productId: patientData?.id,
    purpose: patientData?.treatments?.length ? patientData.treatments[patientData.treatments.length - 1]?.purpose : null,
    previousSystemicOncoTherapy: patientData?.treatments?.length ? patientData.treatments[patientData.treatments.length - 1]?.previousSystemicOncoTherapy : null,
    previousOncoSurgery: patientData?.treatments?.length ? patientData.treatments[patientData.treatments.length - 1]?.previousOncoSurgery : null,
    previousRadiationTherapy: patientData?.treatments?.length ? patientData.treatments[patientData.treatments.length - 1]?.previousRadiationTherapy : null,
    currentProtocol: patientData?.treatments?.length ? patientData.treatments[patientData.treatments.length - 1]?.currentProtocol : null,
    gender: patientData?.customer?.gender || '', // Adiciona o gênero para edição
  });
  const [localPatientData, setLocalPatientData] = useState(patientData);

  const handleEditGender = () => {
    setGenderModalOpen(true); // Abre o modal de edição de gênero
  };

  const handleSaveGender = async () => {
    try {
      const updatedData = {
        personalNumber: patientData?.customer?.personalNumber,
        name: patientData?.customer?.name,
        email: patientData?.customer?.email,
        phone: patientData?.customer?.phone,
        gender: editData.gender // Atualiza o gênero
      };

      // Chama o upsertCustomer com os novos dados do cliente
      await upsertCustomer(updatedData);
      updatePatient({ ...patientData, customer: { ...patientData.customer, gender: editData.gender } }); // Atualiza o contexto do paciente
      setSuccessModal(true);
    } catch (error) {
      setErrorModal(true);
      console.error('Erro ao atualizar o gênero:', error);
    } finally {
      setGenderModalOpen(false);
    }
  };

  useEffect(() => {
    setEditData(prev => ({ ...prev, productId: patientData?.id, gender: patientData?.customer?.gender || '' }));
    setLocalPatientData(patientData);
  }, [patientData]);

  const statusLabels = {
    'imported': 'Ativo',
    'obito_por_cancer': 'Óbito por câncer',
    'obito_por_outra_causa': 'Óbito por outra causa',
    'obito_desconhecido': 'Óbito desconhecido',
    'saida_do_estudo_por_opcao': 'Saída do estudo por opção',
  };

  const purposeLabels = {
    'adjuvante': 'Adjuvante',
    'neoadjuvante': 'Neoadjuvante',
    'opaliativo': 'Paliativo',
  };

  const handleEdit = (item) => {
    setEditData(item);
    setEditModalOpen(true);
  };

  const handleConfirmStatusSave = async () => {
    try {
      await updateProduct(patientData.id, { status: editData.status });

      setLocalPatientData(prev => ({
        ...prev,
        status: editData.status
      }));

      console.log('Status do tratamento atualizado com sucesso!');
      setSuccessModal(true);
    } catch (error) {
      console.error('Erro ao atualizar o status do tratamento:', error);
      setErrorModal(true);
    } finally {
      setEditModalOpen(false);
    }
  };

  const handleConfirmSave = async () => {
    try {
      const response = await createTreatment(editData);
      addTreatment(response.data);
      console.log('Tratamento atualizado com sucesso!');
      setConfirmModalOpen(false);
      setSuccessModal(true);
    } catch (error) {
      setErrorModal(true);
      console.error('Erro ao atualizar o tratamento:', error);
    }
  };

  useEffect(() => {
    setEditData(prev => ({ ...prev, productId: patientData?.id }));
    setLocalPatientData(patientData);
  }, [patientData]);

  const drugOptions = drugList.map(drug => ({ label: drug, value: drug }));

  const handleDrugChange = selectedOptions => {
    const selectedDrugs = selectedOptions.map(option => option.value);
    setEditData({...editData, currentProtocol: selectedDrugs});
  };

  useEffect(() => {
    if (editModalOpen) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [editModalOpen]);

  useEffect(() => {
    fetch('/drogas.csv')
        .then(response => response.text())
        .then(csvData => {
          Papa.parse(csvData, {
            header: true,
            complete: results => {
                const allDrugNames = results.data.map(item => Object.values(item)[0]);
                setDrugList(allDrugNames);
            }
        });
        });
  }, []);

  useEffect(() => {
    if (confirmModalOpen) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [confirmModalOpen]);

  useEffect(() => {
    const lastTreatment = patientData?.treatments?.[patientData.treatments.length - 1] || {};

    setEditData(prev => ({
      ...prev,
      productId: patientData?.id,
      purpose: lastTreatment?.purpose || '',
      previousSystemicOncoTherapy: lastTreatment?.previousSystemicOncoTherapy || false,
      previousOncoSurgery: lastTreatment?.previousOncoSurgery || false,
      previousRadiationTherapy: lastTreatment?.previousRadiationTherapy || false,
      currentProtocol: lastTreatment?.currentProtocol ? lastTreatment.currentProtocol.map(item => item) : [],
      status: patientData?.status || 'imported',
      startDate: lastTreatment?.startDate ? new Date(lastTreatment.startDate) : new Date()
    }));
  }, [patientData]);
  
  return (
    <>
      <HealthCustomCard>
        <div className={styles.profileCard}>
          <div className={styles.profileCardLeft}>
            <div className={styles.profileCardLeftTop}>
              <img src={ProfileImage} alt="Kollecta Health"/>
              <h1>{patientData?.customer?.name}</h1>
            </div>

            <div className={styles.profileCardLeftInfos}>
              <div className={styles.profileCardLeftContent}>
                <p>
                  <span>CPF</span>
                  {patientData?.customer?.personalNumber}
                </p>

                <p>
                  <span>Nº do Estudo</span>
                  {patientData?.studyNumber}
                </p>

                <p>
                  <span>Data de Nascimento</span>
                  {moment(patientData?.customer?.birthday).format('DD/MM/YYYY')}
                </p>
              </div>

              <div className={styles.profileCardLeftContent}>
                <p>
                  <span>Celular</span>
                  {patientData?.customer?.phone}
                </p>

                <p className={styles.gender}>
                  <div className={styles.genderContent}>
                    <span>Gênero</span>
                    {patientData?.customer?.gender && patientData?.customer?.gender[0]}
                  </div>
                  <img
                    src={editIcon}
                    alt="Edit"
                    className={styles.editIcon}
                    onClick={handleEditGender} // Função para editar gênero
                  />
                </p>
              </div>
            </div>
          </div>

          <div className={styles.profileCardRight}>
            <div className={styles.subtitle}>
              <h3> Informações do Estudo </h3>
              <img src={LeftIcon} alt="Kollecta Health"/>
            </div>

            <div className={styles.profileCardRightContent}>
              <p>
                <span>Data do Estudo Atual</span>
                {moment(patientData?.createdAt).format('DD/MM/YYYY')}
              </p>
            </div>

            <div className={`${localPatientData?.status === 'imported' ? styles.statusActive : styles.statusInactive}`}>
              <p>
                <span>Condição do Tratamento</span>

                <div className={styles.editContainer}>
                  {statusLabels[localPatientData?.status] || '-'} 
                  <img
                      src={editIcon}
                      alt="Edit"
                      className={styles.editIcon}
                      onClick={() => handleEdit(localPatientData)}
                    />
                </div>
              </p>
            </div>

            <div className={styles.profileCardRightContent}>
              <p>
                <span>Tratamento Atual</span>
                {patientData?.treatments?.length ? patientData.treatments[patientData.treatments.length - 1]?.currentProtocol : '-'}
              </p>
            </div>


            <div className={styles.profileCardRightContent}>
              <button onClick={() => setConfirmModalOpen(true)} className={styles.generalButton}>Alterar Tratamento</button>
            </div>
          </div>
        </div>
      </HealthCustomCard>

      {/* Modal para editar Gênero */}
      {genderModalOpen && (
        <Modal onClose={() => setGenderModalOpen(false)}>
          <div className={styles.containerModal}>
            <h2>Editar Gênero</h2>
            <select
              value={editData.gender}
              onChange={e => setEditData({ ...editData, gender: e.target.value })}
              className={styles.selectStyle}
            >
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
            <div className={styles.buttonsContainer}>
              <button onClick={handleSaveGender} className={styles.buttonSuccess}>Salvar</button>
              <button onClick={() => setGenderModalOpen(false)} className={styles.buttonError}>Cancelar</button>
            </div>
          </div>
        </Modal>
      )}

      {successModal && (
        <Modal onClose={() => setSuccessModal(false)}>
          <div className={styles.containerModal}>
            <img src={modalGreen} alt="Success Icon"/>
            <h2>Gênero atualizado com sucesso!</h2>
          </div>
        </Modal>
      )}

      {errorModal && (
        <Modal onClose={() => setErrorModal(false)}>
          <div className={styles.containerModal}>
            <img src={modalRed} alt="Error Icon"/>
            <h2>Erro ao atualizar o gênero!</h2>
            <p>Tente novamente</p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Profile;
