import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './style.module.css';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import HealthHeader from '../../../Components/HealthHeader';
import Profile from './Profile';
import HistoryCard from './HistoryCard';
import DetailsCard from './DetailsCard';
import LeftIcon from './leftIcon.svg';
import DetailsCardTreatment from './DetailsCardTreatment';

const ProductInternal = () => {
  return (
    <div className={styles.container}>
      <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
        <section>
          <div className={styles.top}>
            <HealthHeader title="Perfil de Pacientes" />
          </div>     

          <div className={styles.topCardsContainerTop}>
            <Profile />
            <HistoryCard />            
          </div>
        </section>

        <section className={styles.cardContainer}>
          <div className={styles.subtitle}>
            <h3> Características da Doença  </h3>
            <img src={LeftIcon} alt="Kollecta Health"/>
          </div>
          <DetailsCard />
        </section>

        <section className={styles.cardContainer}>
          <div className={styles.subtitle}>
            <h3>Tratamento  </h3>
            <img src={LeftIcon} alt="Kollecta Health"/>
          </div>
          <DetailsCardTreatment />
        </section>
      </ReactCSSTransitionGroup>
    </div>
  );
};

export default ProductInternal;
