import React from 'react';
import styles from './style.module.css'; // Make sure this points to your CSS module file

const HealthPatientStatsTable = ({ data, header, totalData }) => {
  const hexToRGB = (hex) => {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `${r},${g},${b}`;
  }

  return (
    <div className={styles.statsTable}>
       <div className={styles.statsRowHeader}>
        {header.map((header, index) => (
          <div key={index} className={styles.statsCellHeader}>{header}</div>
        ))}
      </div>

      {data.map((row, index) => (
        <div
          key={index}
          className={styles.statsRow}
          style={{
            color: row.color,
            backgroundColor: `rgba(${hexToRGB(row.color)}, 0.15)`
          }}
        >
          <div className={styles.statsCell}>{row.description}</div>
          <div className={styles.statsCell}>{row.count}</div>
          <div className={styles.statsCell}>{row.percentage}</div>
        </div>
      ))}
       <div
          className={styles.totalData}
        >
          <div className={styles.statsCell}>{totalData.description}</div>
          <div className={styles.statsCell}>{totalData.count}</div>
          <div className={styles.statsCell}>{totalData.percentage}</div>
        </div>
    </div>
  );
};

export default HealthPatientStatsTable;
