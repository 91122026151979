import SearchContainer from '../../../Container/Dashboard/Search';

const Search = () => {

	return (
		<SearchContainer />
	)
}

export default Search;
