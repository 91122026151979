import React, { createContext, useState, useContext } from 'react';

const PatientContext = createContext();

export const PatientProvider = ({ children }) => {
  const [patientData, setPatientData] = useState({});

  const updatePatient = (newData) => {
    setPatientData(newData);
  };

  const addTreatment = (newTreatment) => {
    setPatientData(prevData => ({
        ...prevData,
        treatments: [...prevData.treatments, newTreatment]
    }));
};

  return (
    <PatientContext.Provider value={{ patientData, updatePatient, addTreatment }}>
      {children}
    </PatientContext.Provider>
  );
};

export const usePatient = () => useContext(PatientContext);
