import styles from './style.module.css';
import EmergencyLogo from './icons/emergency.svg';

const HealthCards = ({ items, type }) => {

  if (type === 'icon') {
    return (
      <div className={`${styles.cardContainer} ${styles.iconCard}`}>
        {items.map((item, index) => (
          <div key={index} className={styles.cardItem}>
            <h1>{item.title}</h1>

            <div className={styles.content}>
              <div className={styles.iconContainer}>
                <img src={EmergencyLogo} alt="Emergency" />
              </div>

              <div className={styles.subItemsContainer}>
                {item.items.map((subItem, subIndex) => (
                  <div key={subIndex}>
                    
                    <div className={styles.itemContent}>
                      <h2>{subItem.label}</h2>
                      <p>{subItem.value}</p>
                    </div>
                  </div>
                ))}
              </div>             
            </div>

            
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className={styles.cardContainer}>
      {items.map((item, index) => (
        <div key={index} className={styles.cardItem}>
          <h1>{item.value}</h1>
          <p>{item.label}</p>
        </div>
      ))}
    </div>
  );
};

export default HealthCards;