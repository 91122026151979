import React from 'react';
import styles from './style.module.css';
import IconLoading from '../../Icons/IconLoading';
import approveIcon from '../../Icons/images/approve.svg';
import errorIcon from '../../Icons/images/error.svg';
import { IoIosClose } from 'react-icons/io';

const ModalStatus = ({ type, onClose }) => {
  const zIndex = type ? 1000 : -1;

  const renderContent = () => {
    if (type === 'isLoading') {
      return (
        <div className={styles.modalContent}>
          <div className={styles.top}>
            <button onClick={() => onClose()} className={styles.close}>
              <IoIosClose size={24} />
            </button>
          </div>
          <div className="loading-icon"><IconLoading /></div>
          <p><b>Aguarde um instante...</b></p>
          <p>Estamos gerando o seu relatório.</p>
        </div>
      );
    } else if (type === 'isSuccess') {
      return (
        <div className={styles.modalContent}>
          <div className={styles.top}>
            <button onClick={() => onClose()} className={styles.close}>
              <IoIosClose size={24} />
           </button>
          </div>
          <div className="success-icon"><img src={approveIcon} alt="Exportação com sucesso!" /></div>
          <p><b>Relatório gerado!</b></p>
          <p>Seu relatório foi gerado com sucesso!</p>
        </div>
      );
    } else if (type === 'isError') {
      return (
        <div className={styles.modalContent}>
          <div className={styles.top}>
            <button onClick={() => onClose()} className={styles.close}>
              <IoIosClose size={24} />
            </button>
          </div>
          <div className="success-icon"><img src={errorIcon} alt="Exportação com erro!" /></div>

          <p><b>Erro ao gerar relatório!</b></p>
          <p>Tente fazer uma nova exportação.</p>
        </div>
      );
    }
  };

  return (
    <div className={styles.modal} style={{ zIndex: zIndex }}>
      {renderContent()}
    </div>
  );
};

export default ModalStatus;
