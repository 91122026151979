import { useRef, useState } from 'react';
import styles from './style.module.css';
import Icon from './uploadIcon.svg';
import LoadingIcon from './loading.svg';
import Modal from '../Modal';
import success from './success.svg';
import error from './error.svg';
import warning from '../../assets/images/warningOrange.svg';
import { uploadFile } from '../../services/kollectaHealthCore';

const UploadFileAndList = ({ dataForm, setDataFormAfterUse }) => {
  const filesElement = useRef(null);
  const [files, setFiles] = useState([]);
  const [showListInternal, setShowListInternal] = useState(false);
  const [popUpFeedBack, setPopUpFeedBack] = useState({
    loading: false,
    datesImported: false,
    success: false,
    apiError: false,
    contractError: false,
    downloadError: false,
    typeError: false,
    totalImported: 0,
    total: 0,
    dataForm: null,
  });

  const verifyFile = async () => {
    setPopUpFeedBack({ ...popUpFeedBack, loading: true });
    const newDataForm = new FormData();
    try {
      for (const file of filesElement.current.files) {
        setFiles(prevFiles => [...prevFiles, file]);
        newDataForm.append('file', file);
      }

      // Simulação da verificação e leitura do arquivo
      setTimeout(() => {
        setShowListInternal(true);
        setPopUpFeedBack(prev => ({
          ...prev,
          loading: false,
          confirm: true,
          dataForm: newDataForm,
        }));
      }, 1000); // Simula a leitura do arquivo
    } catch (error) {
      console.error(error);
      setPopUpFeedBack({
        ...popUpFeedBack,
        loading: false,
        typeError: true,
        message: error.message,
      });
    }
  };

  const sendFile = async () => {
    try {
      setPopUpFeedBack(prev => ({ ...prev, loading: true, confirm: false }));
      const response = await uploadFile(popUpFeedBack.dataForm);

      if (response.data?.failedProducts && response.data?.failedProducts.length > 0) {
        setPopUpFeedBack(prev => ({
          ...prev,
          loading: false,
          confirm: false,
          apiError: true,
          failedProducts: response.data?.failedProducts
        }));
      } else {
        setPopUpFeedBack(prev => ({ ...prev, success: true, loading: false, confirm: false }));
      }
    } catch (error) {
      console.error(error);
      setPopUpFeedBack(prev => ({ ...prev, success: false, loading: false, typeError: true, confirm: false }));
    }
  };

  const errorMessages = {
    "Study number already exists": "Registro do Paciente já existe.",
  };

  const translateError = (errorMessage) => {
    return errorMessages[errorMessage] || errorMessage;
  };

  return (
    <div className={styles.uploadFileContainer}>

      { !showListInternal && (
        <div className={styles.contentInputFile}>
          <div className={styles.inputFileUploadContainer}>
            <input
              className={styles.inputFile}
              id="my-file"
              type="file"
              accept=".csv, .xlsx"
              onChange={verifyFile}
              ref={filesElement}
            />
            <label tabIndex="0" htmlFor="my-file" className={styles.inputFileTrigger}>
              <span className={styles.triggerIcon}><img className={styles.inputIcon} src={Icon} alt="upload files" /></span>
              Arraste seu arquivo Excel aqui
              <h5>ou</h5>
              <label tabIndex="0" htmlFor="my-file" className={styles.btnUpload}>
                Buscar arquivo
              </label>
            </label>
          </div>
        </div>
        )
      }
     

      {showListInternal && (
        <div className={styles.list}>
          <h1> Arquivos cadastrados </h1>
          <div className={styles.listContainer}>
            <div className={styles.fileItems}>
              {files.map((file, index) => (
                <div className={styles.fileItem} key={index}>
                  <h3 className={styles.itemNameFile}>{file.name}</h3>
                </div>
              ))}
            </div>
          </div>
          <button className={styles.btnSubmit} onClick={sendFile}>Fazer Upload dos Arquivos</button>
        </div>
      )}

      {/* Modais para diferentes estados */}
      {popUpFeedBack.loading && (
        <Modal onClose={() => setPopUpFeedBack(prev => ({ ...prev, loading: !prev.loading }))}>
          <div className={styles.modalContainer}>
            <img src={LoadingIcon} alt='Icone de carregamento' className={styles.loadingIcon}/>
            <p>Aguarde um instante...</p>
            <p>Estamos fazendo o Upload dos arquivos.</p>
          </div>
        </Modal>
      )}

      {popUpFeedBack.success && (
        <Modal onClose={() => {
          setPopUpFeedBack(prev => ({ ...prev, success: !prev.success }));
          window.location.reload();
        }}>
          <div className={styles.modalContainer}>
            <img src={success} alt="success" />
            <h3>Carregamento realizado!</h3>
            <p>Os arquivos foram carregados com sucesso.</p>
          </div>
        </Modal>
      )}

      {popUpFeedBack.typeError && (
        <Modal onClose={() => setPopUpFeedBack(prev => ({ ...prev, typeError: false }))}>
          <div className={styles.modalContainer}>
            <img src={error} alt="error" />
            <p>Não foi possível importar o arquivo! Código do erro: KH-001</p>
            <button className={styles.btnSubmit} onClick={() => window.location.reload()}>
              Fechar
            </button>
          </div>
        </Modal>
      )}

      {popUpFeedBack.contractError && (
        <Modal onClose={() => setPopUpFeedBack(prev => ({ ...prev, contractError: false }))}>
          <div className={styles.modalContainer}>
            <img src={error} alt="error" />
            <p>Contrato {popUpFeedBack.contractError} já existe! Código do erro: KC-010</p>
            <button className={styles.btnSubmit} onClick={() => window.location.reload()}>
              Fechar
            </button>
          </div>
        </Modal>
      )}

    {popUpFeedBack.apiError && (
      <Modal onClose={() => setPopUpFeedBack(prev => ({ ...prev, apiError: false }))}>
        <div className={styles.modalContainer}>
          <img src={error} alt="error" />
          <h3>Erro ao realizar o carregamento!</h3>
          {popUpFeedBack.failedProducts.map((product, index) => (
            <div key={index} className={styles.modalErrorContent}>
              <p><strong>Registro do paciente:</strong> {product?.studyNumber}</p>
              <p><strong>CPF:</strong> {product?.personalNumber}</p>
              <p><strong>Motivo:</strong> {translateError(product.reason)}</p>
            </div>
          ))}
          <button className={styles.btnSubmit} onClick={() => setPopUpFeedBack(prev => ({ ...prev, apiError: false }))}>
            Fechar
          </button>
        </div>
      </Modal>
    )}
    </div>
  );
};

export default UploadFileAndList;
