import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePatient } from '../../../../Contexts/PatientContext';
import styles from './style.module.css';
import EyeLogo from './eye.svg';

const SearchTable = ({ data }) => {
  const navigate = useNavigate();
  const { updatePatient } = usePatient();

  const handleViewProfile = (itemData) => {
    updatePatient(itemData);
    navigate('/dashboard/paciente');
  };

  const statusLabels = {
    'imported': 'Ativo',
    'obito_por_cancer': 'Óbito por câncer',
    'obito_por_outra_causa': 'Óbito por outra causa',
    'obito_desconhecido': 'Óbito desconhecido',
    'saida_do_estudo_por_opcao': 'Saída do estudo por opção',
  };

  return (
    <div className={styles.tableContainer}>
      <table className={styles.customTable}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>Registro do Paciente</th>
            <th>Condição do Tratamento</th>
            <th>Ver Perfil do Paciente</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.customer?.name}</td>
              <td>{item.customer?.personalNumber}</td>
              <td>{item.studyNumber}</td>
              <td>{statusLabels[item.status]}</td>
              <td>
                <button
                  className={styles.profileButton}
                  onClick={() => handleViewProfile(item)}
                >
                  <img src={EyeLogo} alt="Ver Perfil" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SearchTable;
