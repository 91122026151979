import React, { useEffect, useState } from 'react'
import LoginContainer from '../../../Container/Dashboard/Login'
import { useLocation, useNavigate } from "react-router-dom"

import { useDispatch } from 'react-redux'
import { pathOr } from 'ramda'
import { setCompanySettings } from '../../../Redux/Reducers/CompanySettings'

import { auth } from '../../../services/kollectaApi'
import LogRocket from 'logrocket';

import ReactGA from 'react-ga';

const Login = () => {
  const [form, setForm] = useState({
    email: '',
    password: '',
  })
  const [loggedIn, setLoggedIn] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  
  const dispatch = useDispatch()
  
  let navigate = useNavigate()
  const location = useLocation();


  const getCompanySettingsScreeng = async () => {
      const locationPath = pathOr(
        'https://portal.kollecta.io',
        ['location', 'href'],
        window,
      );
          navigate("/offers/customer");
      
      try {
        const { host, hostname } = new URL(locationPath);
        

        if (hostname.startsWith('www.') || hostname.startsWith('portal.')) {
          window.location.href = "https://kollecta.app";

       } else if (host.includes('kollecta.app')) {
          // navigate("/offers/customer");
        }
        
        const { data } = false;

        if (data && data.siteUrl) {
          if (data) {
            document.documentElement.style.setProperty('--portal-header-primary', data.primaryColor);
            document.documentElement.style.setProperty('--portal-header-secondary', data.secondaryColor);
            document.documentElement.style.setProperty('--portal-logo', data.logo);
          }
        }
        setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <LoginContainer />
  )
}

export default Login
