import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import LeftIcon from './leftIconWhite.svg';
import ClockIcon from './clock.svg';
import CalendarIcon from './calendar.svg';
import HealthCustomCard from '../../../../Components/HealthCustomCard';
import { getLastTwoBotHistories } from '../../../../services/kollectaHealthCore';
import HistoryRadios from '../../../../Components/HistoryRadios';
import { useNavigate } from 'react-router-dom';
import * as moment from 'moment';
import { usePatient } from '../../../../Contexts/PatientContext';

const HistoryCard = () => {
  const [lastTwoHistories, setLastTwoHistories] = useState([]);
  const { patientData } = usePatient();
  const navigate = useNavigate();

  const redirectToHistory = () => {
    navigate('/dashboard/historico');
  };

  useEffect(() => {
    if (patientData && patientData.customer && patientData.customer.personalNumber) {
      getLastTwoBotHistories(patientData.customer.personalNumber)
        .then(histories => {
          setLastTwoHistories(histories?.data);
        })
        .catch(error => {
          console.error('Erro ao recuperar históricos do bot:', error);
        });
    }
  }, [patientData]);

  const validResponses = ["nenhuma", "leve", "moderada", "grave", "muito grave"];

  const renderResponseContent = (answer) => {
    if (answer) {
      if (validResponses.includes(answer.toLowerCase())) {
        return <HistoryRadios selectedResponse={answer} />;
      } else {
        return <span>{answer || 'Sem resposta'}</span>;
      }
    }
  };

  return (
    <HealthCustomCard noPadding>
      <div className={styles.historyCard}>
        <div className={styles.historyCardTop}>

          <div>
            <div className={styles.subtitle}>
                <h3> Última Resposta do Paciente </h3>
                <img src={LeftIcon} alt="Kollecta Health"/>
              </div>   

              <div className={styles.details}>
                {patientData?.customer?.name} - Tratamento Atual: {patientData?.treatments && patientData?.treatments[0]?.currentProtocol}
              </div>

              <div className={styles.details}>
                <img src={CalendarIcon} alt="Calendário Icone" />
                <span>{moment(lastTwoHistories[0]?.createdAt).format('DD/MM/YYYY')}</span>
                <img src={ClockIcon} alt="Relógio Icone" className={styles.marginLeft}/>
                <span>{moment(lastTwoHistories[0]?.createdAt).format('HH:mm')}</span>
              </div>
          </div>

          <div className={styles.buttonContainer}>
            <button type="button" onClick={(redirectToHistory)}>
              Ver Histórico de Respostas
            </button>
          </div>
        </div>
        <div className={styles.historyCardRight}>
          <div className={styles.historyCardRightContent}>
            <div>
              {lastTwoHistories.map((history, index) => (
                <div key={index}>
                  <p> {history.question} </p>
                  {renderResponseContent(history.answer)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </HealthCustomCard>
  );
};

export default HistoryCard;
