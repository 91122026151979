import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import Login from './Pages/Dashboard/Login';
import HomeDashPage from './Pages/Dashboard/Home';
import Search from './Pages/Dashboard/Search';
import Register from './Pages/Dashboard/Register';

import { Layout } from './Components';
import ProtectedRoute from './protectRoute';
import ProductDetails from './Pages/Dashboard/ProductDetails';
import BotHistory from './Pages/Dashboard/BotHistory';

const App = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="dashboard">
        <Route path="home" element={<ProtectedRoute><Layout dashBoard><HomeDashPage /></Layout></ProtectedRoute>} />
        <Route path="pesquisa" element={<ProtectedRoute><Layout dashBoard><Search /></Layout></ProtectedRoute>} />
        <Route path="cadastro" element={<ProtectedRoute><Layout dashBoard><Register /></Layout></ProtectedRoute>} />
        <Route path="paciente" element={<ProtectedRoute><Layout dashBoard><ProductDetails /></Layout></ProtectedRoute>} />
        <Route path="historico" element={<ProtectedRoute><Layout dashBoard><BotHistory /></Layout></ProtectedRoute>} />
      </Route>
      <Route path="*" to="/" element={<Navigate to="/login" replace />} />
    </Routes>
  )
}

export default App;
