import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './style.module.css';
import { resetPassword } from '../../../services/kollectaHealthCore';

const CreatePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const passwordValidation = {
    minLength: password.length >= 8,
    lowercase: /[a-z]/.test(password),
    uppercase: /[A-Z]/.test(password),
    number: /\d/.test(password),
    specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    passwordsMatch: password === confirmPassword,
  };

  const handleResetPassword = async () => {
    if (!token) {
      setError('Token inválido ou ausente.');
      return;
    }

    if (!passwordValidation.passwordsMatch) {
      setError('As senhas não coincidem');
      return;
    }

    try {
      const response = await resetPassword(token, password);
      if (response.success) {
        navigate('/login'); 
      } else {
        setError(response.message || 'Erro ao redefinir a senha');
      }
    } catch (error) {
      console.error('Erro ao redefinir a senha:', error);
      setError('Erro ao redefinir a senha');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <h2>Definição de senha</h2>
      </div>

      <div className={styles.message}>
        Para realizar o primeiro login, informe abaixo a sua senha de acesso:
      </div>

      <div className={styles.inputWrapper}>

        <div className={styles.inputWrapperItem}>
          Senha*
          <input
            type="password"
            placeholder="Nova senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className={styles.inputWrapperItem}>
          <p> Sua senha deve conter: </p>
          <div>
            <p className={passwordValidation.minLength ? styles.valid : styles.invalid}>
              Pelo menos 8 caracteres
            </p>
            <p className={passwordValidation.lowercase ? styles.valid : styles.invalid}>
              1 caractere minúsculo
            </p>
            <p className={passwordValidation.uppercase ? styles.valid : styles.invalid}>
              1 caractere maiúsculo
            </p>
            <p className={passwordValidation.number ? styles.valid : styles.invalid}>
              1 número
            </p>
            <p className={passwordValidation.specialChar ? styles.valid : styles.invalid}>
              1 caractere especial
            </p>
          </div>
        </div>


        <div className={styles.inputWrapperItem}>
          Confirmação de Senha*
          <input
            type="password"
            placeholder="Confirme a nova senha"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
      <button
        className={styles.button}
        onClick={handleResetPassword}
        disabled={!password || !confirmPassword || Object.values(passwordValidation).includes(false)}
      >
        SALVAR SENHA
      </button>
    </div>
  );
};

export default CreatePassword;
