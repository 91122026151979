import React, { useState } from 'react';
import styles from './style.module.css';

const Tabs = ({ tabCount, activeIndex }) => {
  return (
    <div className={styles.tabs}>
      {Array.from({ length: tabCount }).map((_, index) => (
        <div
          key={index}
          className={`${styles.tab} ${index === activeIndex ? styles.active : ''}`}
        />
      ))}
    </div>
  );
};

export default Tabs;
