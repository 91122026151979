import React, { useState, useEffect, useRef } from 'react';
import styles from './style.module.css';
import HealthCustomCard from '../../../../Components/HealthCustomCard';
import { updateTreatment } from '../../../../services/kollectaHealthCore';
import editIcon from './edit.svg'; 
import * as moment from 'moment';
import Modal from '../../../../Components/Modal';
import CustomDatePicker from '../../../../Components/DatePicker';
import modalRed from '../redModalIcon.svg';
import modalGreen from '../greenModalIcon.svg';
import { usePatient } from '../../../../Contexts/PatientContext';

const DetailsCardTreatment = () => {
  const { patientData } = usePatient();
  const [treatments, setTreatments] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [editData, setEditData] = useState({});
  const modalRef = useRef(null);

  useEffect(() => {
    if (patientData && patientData.treatments) {
      const mappedTreatments = patientData.treatments.map(treatment => ({
        id: treatment.id,
        items: [
          {
            label: 'Data de Início do Tratamento Sistêmico',
            field: treatment.startDate,
            editable: true,
            type: 'date',
            fieldKey: 'startDate'
          },
          {
            label: 'Data Final do Tratamento Sistêmico',
            field: treatment.endDate,
            editable: true,
            type: 'date',
            fieldKey: 'endDate'
          },
          {
            label: 'Protocolo Atual',
            field: treatment.currentProtocol,
            editable: true,
            type: 'array',
            fieldKey: 'currentProtocol'
          },
          {
            label: 'Finalidade',
            field: treatment.purpose,
            editable: true,
            type: 'text',
            fieldKey: 'purpose'
          },
          {
            label: 'Realizou terapia sistêmica oncológica anteriormente?',
            field: treatment.previousSystemicOncoTherapy,
            editable: true,
            type: 'boolean',
            fieldKey: 'previousSystemicOncoTherapy'
          },
          {
            label: 'Realizou cirurgia oncológica?',
            field: treatment.previousOncoSurgery,
            editable: true,
            type: 'boolean',
            fieldKey: 'previousOncoSurgery'
          },
          {
            label: 'Realizou radioterapia?',
            field: treatment.previousRadiationTherapy,
            editable: true,
            type: 'boolean',
            fieldKey: 'previousRadiationTherapy'
          },
        ]
      }));
      setTreatments(mappedTreatments);
    }
  }, [patientData]);

  const renderFieldContent = (item) => {
    if (item.type === 'date' && item.field) {
      return moment(item.field).format('DD/MM/YYYY') || '';
    } else if (item.type === 'array' && Array.isArray(item.field)) {
      return item.field.join(', ');
    } else if (item.type === 'boolean') {
      return item.field ? 'Sim' : 'Não';
    }
    return item.field;
  };

  const handleEdit = (treatment, item) => {
    setEditData({...item, treatmentId: treatment.id});
    setEditModalOpen(true);
  };

  const renderEditInput = (item) => {
    if (item.type === 'date') {
      return (
        <CustomDatePicker 
          onDateChange={(date) => setEditData({ ...editData, field: date })}
          minDateEnabled={false}
        />
      );
    } else if (item.type === 'boolean') {
      return (
        <select
          value={editData.field ? 'true' : 'false'}
          onChange={(e) => setEditData({ ...editData, field: e.target.value === 'true' })}
        >
          <option value="true">Sim</option>
          <option value="false">Não</option>
        </select>
      );
    } else if (item.type === 'array') {
      return (
        <input
          type="text"
          value={editData.field.join(', ')}
          onChange={(e) => setEditData({ ...editData, field: e.target.value.split(',').map(str => str.trim()) })} // Converte a string de volta para um array
        />
      );
    }
    return (
      <input
        type="text"
        value={editData.field}
        onChange={(e) => setEditData({ ...editData, field: e.target.value })}
      />
    );
  };

  const handleProceedEdit = () => {
    setEditModalOpen(false);
    setConfirmModalOpen(true);
  };

  const handleCancelEdit = () => {
    setEditModalOpen(false);
    setConfirmModalOpen(false);
  };

  const handleConfirmEdit = async () => {
    try {
      const updatedField = { [editData.fieldKey]: editData.field };
      
      const response = await updateTreatment(editData.treatmentId, updatedField);
      
      if (response.success) {
        const updatedTreatments = treatments.map(treatment =>
          treatment.id === editData.treatmentId ? {
            ...treatment,
            items: treatment.items.map(item =>
              item.fieldKey === editData.fieldKey ? { ...item, field: editData.field } : item
            )
          } : treatment
        );
        setTreatments(updatedTreatments);
        setSuccessModal(true);
      } else {
        setErrorModal(true);
      }
    } catch (error) {
      console.error('Failed to update treatment:', error);
      setErrorModal(true);
    } finally {
      setConfirmModalOpen(false);
    }
  };

  useEffect(() => {
    if (editModalOpen || confirmModalOpen) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [editModalOpen, confirmModalOpen]);

  return (
    <HealthCustomCard noPadding>
      <section className={styles.wrapper}>
        {[...treatments].reverse().map((treatment, idx) => (
          <div key={idx} className={styles.detailCard}>
            {treatment.items.map((item, index) => (
              <div key={index} className={styles.detailCardItem}>
                {idx === 0 && <h1>{item.label}</h1>}
                <div className={styles.editContainer}>
                  <p>{renderFieldContent(item)}</p>
                  {item.editable && (
                    <img
                      src={editIcon}
                      alt="Edit"
                      className={styles.editIcon}
                      onClick={() => handleEdit(treatment, item)}
                    />
                  )}
                </div>
              </div>
            ))} 
          </div>
        ))}
        {editModalOpen && (
          <div ref={modalRef}>
            <Modal onClose={handleCancelEdit}>
              <div className={styles.containerModal}>
                <h2>Editar {editData.label}</h2>
                {renderEditInput(editData)}
                <div className={styles.buttonsContainer}>
                  <button onClick={handleProceedEdit} className={styles.buttonSuccess}>Continuar</button>
                  <button onClick={handleCancelEdit} className={styles.buttonError}>Cancelar</button>
                </div>
              </div>
            </Modal>
          </div>
        )}
        {confirmModalOpen && (
          <div ref={modalRef}>
            <Modal onClose={() => setConfirmModalOpen(false)}>
              <div className={styles.containerModal}>
                <img src={modalRed} alt="Red Modal Icon" />
                <p>Tem certeza que deseja continuar com a alteração do tratamento?</p>
                <div className={styles.buttonsContainer}>
                  <button onClick={handleConfirmEdit} className={styles.buttonSuccess}>Sim, confirmar alteração</button>
                  <button onClick={handleCancelEdit} className={styles.buttonError}>Não, cancelar alteração</button>
                </div>
              </div>
            </Modal>
          </div>
        )}
        {successModal && (
          <Modal onClose={() => setSuccessModal(false)}>
            <div className={styles.containerModal}>
              <img src={modalGreen} alt="Green Modal Icon" />
              <h2>Tratamento Alterado</h2>
            </div>
          </Modal>
        )}
        {errorModal && (
          <Modal onClose={() => setErrorModal(false)}>
            <div className={styles.containerModal}>
              <img src={modalRed} alt="Red Modal Icon" />
              <h2>Erro ao alterar Tratamento</h2>
              <p>Tente novamente</p>
            </div>
          </Modal>
        )}
      </section>
    </HealthCustomCard>
  );
};

export default DetailsCardTreatment;
