import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import styles from './style.module.css';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import Logo from '../../../assets/logos/igesp.svg';
import Tabs from '../Tabs';
import LoginCode from '../LoginCode';
import CreatePassword from '../CreatePassword';
import { login, validate2FA, initiateResetPassword } from '../../../services/kollectaHealthCore';
import Modal from '../../../Components/Modal';

const Login = () => {
  const [form, setForm] = useState({ email: '', password: '' });
  const [passwordType, setPasswordType] = useState(true);
  const [showLoginCode, setShowLoginCode] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [error, setError] = useState('');
  const [userId, setUserId] = useState('');
  const [loginCode, setLoginCode] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  

  // Verifica se há um token na URL
  const params = new URLSearchParams(location.search);
  const resetToken = params.get('token');

  const handleLogin = async () => {
    if (!form.email || !form.password) {
      setError('Por favor, preencha o e-mail e a senha');
      return;
    }

    try {
      const response = await login({
        email: form.email,
        password: form.password
      });

      if (response.data?.success) {
        setUserId(response.data.userId);
        setLoginCode(response.data.code);
        setShowLoginCode(true);
        setError('');
      } else {
        setError(response.message);
      }
    } catch (error) {
      console.error('Erro na chamada da API:', error);
      if (error.response && error.response.status === 401) {
        setError('Credenciais inválidas');
      } else {
        setError('Erro ao efetuar login');
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const togglePassword = (event) => {
    event.preventDefault();
    setPasswordType(!passwordType);
  };

  const maskEmail = (email) => {
    const [localPart, domainPart] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 2) + '****' + localPart.slice(-1);
    return `${maskedLocalPart}@${domainPart}`;
  };

  const handleValidateCode = async (userId, code) => {
    try {
      const response = await validate2FA(userId, code);
      if (response.success) {
        return true; // Sucesso na validação do código 2FA
      } else {
        setError(response.message || 'Código inválido');
        return false;
      }
    } catch (error) {
      setError(error.message || 'Erro ao validar o código');
      return false;
    }
  };

  const handleResetPassword = async () => {
    setError('');
    setSuccessMessage('');

    if (!resetEmail) {
      setError('Por favor, insira o e-mail para redefinição de senha');
      return;
    }

    try {
      const response = await initiateResetPassword(resetEmail);
      setSuccessMessage('E-mail de redefinição enviado com sucesso! Verifique sua caixa de entrada.');
      setResetEmail('');
    } catch (error) {
      setError('Erro ao iniciar a redefinição de senha: ' + error.message);
      console.error('Erro na chamada da API:', error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard/home');
    }
  }, [navigate]);

  return (
    <div className={styles.loginContainer}>
      <div className={styles.leftContainer}></div>
      <div className={styles.rightContainer}>
        <div className={styles.cardFormHeader}>
          <img src={Logo} alt="kollecta Health" />
        </div>
        { !resetToken && (
          <div className={styles.tabsContainer}>
            <Tabs tabCount={2} activeIndex={resetToken ? 1 : showLoginCode ? 1 : 0} />
          </div>
        )}

        {resetToken ? (
          <CreatePassword /> // Exibe CreatePassword se o token estiver na URL
        ) : showLoginCode ? (
          <LoginCode
            maskedEmail={maskEmail(form.email)}
            userId={userId}
            onValidateCode={handleValidateCode}
            email={form.email}
            password={form.password}
          />
        ) : (
          <>
            <div className={styles.titleContainer}>
              <h1>Entrar</h1>
              <h2>Faça o login para ver o prontuário dos pacientes</h2>
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.formGroup}>
                <label className={styles.formItem}>
                  E-mail*
                  <input
                    onChange={handleChange}
                    placeholder="Insira o seu e-mail"
                    type="email"
                    value={form.email}
                    name="email"
                  />
                </label>
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formItem}>
                  <button className={styles.passType} onClick={(event) => togglePassword(event)}>
                    {passwordType ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </button>
                  Senha*
                  <div className={styles.passContainer}>
                    <input
                      onChange={handleChange}
                      placeholder="Insira a sua senha"
                      type={passwordType ? "password" : "text"}
                      value={form.password}
                      name="password"
                    />
                  </div>
                </label>
              </div>
            </div>
            <button onClick={handleLogin} className={styles.btnSubmit}>
              ENTRAR
            </button>
            {error && (
              <div className={styles.errorMessage}>
                <small>{error}</small>
              </div>
            )}
             <a onClick={() => setShowResetModal(true)} className={styles.link}>Esqueceu a senha?</a>        
          </>
        )}
      </div>

      {showResetModal && (
        <Modal
          onClose={() => { setShowResetModal(false); setError(''); setSuccessMessage(''); }}
        >
          <div className={styles.modalContainer}>
            <h3>Alterar Senha</h3>
            <input
              type="email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              placeholder="Digite seu email"
              className={styles.resetInput}
            />
            <button onClick={handleResetPassword} className={styles.btnSubmit}>Enviar email de alteração</button>
            {error && <div className={styles.errorMessage}>{error}</div>}
            {successMessage && <div className={styles.successMessage}>{successMessage}</div>}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Login;
