import React from 'react';
import styles from './style.module.css';
import leftIcon from './icons/leftIcon.svg';
import logoutIcon from './icons/logout.svg';
import {
  useNavigate
} from "react-router-dom";

const HealthHeader = ({ title, subtitle}) => {
  const navigate = useNavigate();

  const loggout = async () => {
    localStorage.removeItem('token')
    navigate("/login")
  }

  return (
    <section className={styles.wrapper}>
      <div className={styles.left}>
        <img src={leftIcon} alt="Kollecta Health"/>
        <h3>{title}</h3>
      </div>

      <div className={styles.right}>
        <img src={logoutIcon} alt="Kollecta Health"/>
        <button onClick={loggout}>Sair da conta</button>
      </div>
    </section>
  );
};

export default HealthHeader;