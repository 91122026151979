import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.css';
import Lock from '../../../assets/images/lock.svg';
import { authenticateUser, login } from '../../../services/kollectaHealthCore';

const LoginCode = ({ maskedEmail, receivedUserId, onValidateCode, email, password }) => {
  const [code, setCode] = useState(Array(6).fill(''));
  const [timer, setTimer] = useState(40);
  const [userId, setUserId] = useState(receivedUserId);
  const [error, setError] = useState(''); // Estado para armazenar mensagens de erro
  const inputRefs = useRef([]); // Array para armazenar referências dos inputs
  const navigate = useNavigate();

  const handleInputChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value.slice(-1);
    setCode(newCode);

    // Se não for o último input, mover o foco para o próximo input
    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleValidate = async () => {
    const codeString = code.join('');
    const success = await onValidateCode(userId, codeString);

    if (success) {
      try {
        const response = await authenticateUser(email, password);
        if (response.success) {
          localStorage.setItem('token', response.token);
          navigate('/dashboard/home'); // Redirecionar para a rota protegida
        } else {
          setError('Erro ao autenticar usuário');
        }
      } catch (error) {
        console.error('Erro ao autenticar:', error);
        setError('Erro ao autenticar');
      }
    } else {
      try {
        const response = await login({ email, password });
        if (response.data.success) {
          setUserId(response.data.userId);
          setError('Código incorreto. Um novo código foi enviado.');
        } else {
          setError('Erro ao enviar novo código. Tente novamente.');
        }
      } catch (error) {
        console.error('Erro ao enviar novo código:', error);
        setError('Erro ao enviar novo código. Tente novamente.');
      }
    }
  };

  // Gere um novo código quando o temporizador terminar
  const generateNewCode = async () => {
    try {
      const response = await login({ email, password });
      if (response.data.success) {
        setUserId(response.data.userId);
        setError('Um novo código foi enviado.');
      } else {
        setError('Erro ao enviar novo código. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao enviar novo código:', error);
      setError('Erro ao enviar novo código. Tente novamente.');
    }
  };

  // Use effects  
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setTimer(350); // Reinicie o temporizador
        generateNewCode(); // Solicitar novo código
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <h2>Código de segurança</h2>
        <img src={Lock} alt="kollecta Health" />
      </div>
      <div className={styles.message}>
        Digite abaixo o código que enviamos por E-mail para {maskedEmail}
      </div>
      <div className={styles.inputWrapper}>
        {code.map((char, index) => (
          <input
            key={index}
            ref={el => inputRefs.current[index] = el} // Adiciona referência ao input
            className={styles.input}
            type="text"
            value={char}
            onChange={(e) => handleInputChange(index, e.target.value)}
            maxLength={1}
          />
        ))}
      </div>
      <div className={styles.timer}>
        REENVIAREMOS UM NOVO CÓDIGO EM ({timer})
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
      <button className={styles.button} onClick={handleValidate} disabled={code.includes('')}>
        CONFIRMAR CÓDIGO
      </button>
    </div>
  );
};

export default LoginCode;
