import axios from 'axios';
import axiosInstance from './axiosInstance';

const baseUrl = 'https://api.kollecta.health';

const login = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/v1/users/login`, data)
}

const getStatistics = async () => {
  return await axiosInstance.get(`${baseUrl}/api/v1/statistics`)
}

const validate2FA = async (userId, code) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/api/v1/users/validate-2fa`, {
      userId,
      code
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao validar 2FA');
  }
};

const authenticateUser = async (email, password) => {
  try {
    const response = await axiosInstance.post('/api/v1/users/auth', {
      email,
      password
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao autenticar usuário');
  }
};

const resetPassword = async (token, password) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/api/v1/users/set-password`, {
      token,
      password
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao redefinir a senha');
  }
};

const searchProducts = async ({ customerName, personalNumber, studyNumber }) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/api/v1/products/search`, {
      params: { customerName, personalNumber, studyNumber }
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao buscar produtos');
  }
};

const uploadFile = async (formData, token) => {
  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    };
    const response = await axiosInstance.post(`${baseUrl}/api/v1/products/import`, formData, config);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao fazer upload do arquivo');
  }
};

const exportCustomersByAnswers = async (filters) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    };
    const response = await axiosInstance.post(`${baseUrl}/api/v1/customers/export-by-answer`, { filters }, config);

    return response.data;
  } catch (error) {
    throw error.response ? error.response?.data : new Error('Erro ao exportar dados dos clientes');
  }
};

const exportBotHistoryExcel = async (personalNumber) => {
  try {
    const config = {
      headers: {
        // 'Authorization': `Bearer ${token}`
      },
      responseType: 'blob' // Importante para lidar com a resposta como um arquivo binário
    };
    const response = await axiosInstance.get(`${baseUrl}/api/v1/bot-history/export/excel/${personalNumber}`, config);
    return response.data; // Retorna o blob que pode ser usado para download ou visualização do arquivo
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao exportar o histórico do bot');
  }
};

const getLastTwoBotHistories = async (personalNumber, token) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/api/v1/bot-history/last-two/${personalNumber}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao recuperar os últimos dois históricos do bot');
  }
};

const updateProduct = async (productId, productData) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await axiosInstance.put(`${baseUrl}/api/v1/products/${productId}`, productData, config);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao atualizar produto');
  }
};

const updateTreatment = async (treatmentId, treatmentData) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
    };
    const response = await axiosInstance.put(`${baseUrl}/api/v1/treatments/${treatmentId}`, treatmentData, config);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao atualizar tratamento');
  }
};

const createTreatment = async (treatmentData) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
    };
    const response = await axiosInstance.post(`${baseUrl}/api/v1/treatments`, treatmentData, config);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao atualizar tratamento');
  }
};

const getBotHistoryByPersonalNumber = async (personalNumber, startDate, endDate) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/api/v1/bot-history/by-personal-number/${personalNumber}`, {
      params: { startDate, endDate }
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao recuperar o histórico do bot');
  }
};

const initiateResetPassword = async (email) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/api/v1/users/reset-password`, { email });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao iniciar a redefinição de senha');
  }
};

const exportBotHistoryPDF = async (personalNumber) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    };
    const response = await axiosInstance.get(`${baseUrl}/api/v1/bot-history/export/pdf/${personalNumber}`, config);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao exportar o histórico do bot em PDF');
  }
};

const upsertCustomer = async (customerData) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await axiosInstance.post(`${baseUrl}/api/v1/customers`, customerData, config);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erro ao criar cliente');
  }
};

export {
  login,
  validate2FA,
  authenticateUser,
  resetPassword,
  initiateResetPassword,
  searchProducts,
  uploadFile,
  getStatistics,
  exportCustomersByAnswers,
  exportBotHistoryExcel,
  getLastTwoBotHistories,
  updateProduct,
  updateTreatment,
  getBotHistoryByPersonalNumber,
  createTreatment,
  exportBotHistoryPDF,
  upsertCustomer
}