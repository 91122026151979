import React, { useState } from 'react';
import styles from './style.module.css';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { useEffect } from 'react';
import InputSimple from '../../../Components/InputSimple';
import StatusSelector from '../../../Components/StatusSelector';
import Select from '../../../Components/Select';
import { Button, Table } from '../../../Components';
import image from './image.svg';
import LoadingOverlay from '../../../Components/LoadingOverlay';
import Modal from 'react-modal';
import HealthHeader from '../../../Components/HealthHeader';
import LeftIcon from './leftIcon.svg';
import SearchIcon from './search.svg';
import { searchProducts } from '../../../services/kollectaHealthCore';
import SearchTable from './Table';

const Search = () => {
  const [user, setUser] = useState({});
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [studyNumber, setStudyNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [patients, setPatients] = useState(false);
  const [totalRecords, setTotalRecords] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const handleInputName = (value) => {
    setName(value);
  }

  const handleInputCpf = (value) => {
    const onlyNums = value.replace(/[^0-9]/g, '');
    setCpf(onlyNums);
  }

  const handleStudyNumber = (value) => {
    setStudyNumber(value);
  }


  const closeModal = (item) => {
    setModal(false);
  }

  const handleSearchProducts = async () => {
    setLoading(true);
    setSearchPerformed(true);
    const searchParams = {
      customerName: name,
      personalNumber: cpf,
      studyNumber: studyNumber
    };
  
    try {
      const response = await searchProducts(searchParams);
      if (response && response.data?.products?.length) {
        setPatients(response.data.products);
        setTotalRecords(response.data.count);
        setErrorMessage('');
      } else {

        setPatients([]);
        setErrorMessage('Nenhum paciente encontrado.');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setErrorMessage('Erro ao buscar pacientes');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
  }, [loading]);  

  useEffect(() => {
    const user = localStorage.getItem('user');
    const userParse = JSON.parse(user);
    setUser(userParse);
  }, []);

  useEffect(() => {
    console.log('PACIENTE -> ', patients);
  }, [user, name, cpf, errorMessage, modal, patients]);


	return (
    <div className={styles.searchContainer}>
      <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>

        <LoadingOverlay isVisible={loading}/>

      <div className={styles.top}>
        <HealthHeader title="Buscar Pacientes"/>
      </div>     

      <div className={styles.container}>
        <div className={styles.containerTop}>
          <div className={styles.subtitle}>
            <h3> Pesquisar Pacientes </h3>
            <img src={LeftIcon} alt="Kollecta Health"/>
            <p>Informe os dados abaixo para encontrar o paciente desejado. Utilize os campos disponíveis para inserir o nome, CPF e número de registro do paciente</p>
          </div>

          <div className={styles.filtersContainer}>
            <div className={styles.filtersContent}>
             <InputSimple onValueChange={handleInputName} label="Nome" inputType="text" />

             <InputSimple onValueChange={handleInputCpf} label="CPF" inputType="text" />

             <InputSimple onValueChange={handleStudyNumber} label="Registro do Paciente" inputType="text" />

              <Button onClick={handleSearchProducts}> Pesquisar <img src={SearchIcon} alt="Kollecta Health" /> </Button>
            </div>

          </div>
        </div>

        {searchPerformed && (
          <div className={styles.pastDuesContainer}>
          <div className={styles.subtitle}>
              <h3> Resultado da Pesquisa </h3>
              <img src={LeftIcon} alt="Kollecta Health"/>
            </div>
            { patients?.length ? 
              <>
                <SearchTable data={patients} />
              </> : null
              }

            { totalRecords === 0 || !totalRecords ? (
              <div className={styles.empty}>
                <img src={image} alt="Lupa pesquisando no CRM" />
                <h3>Paciente não foi encontrado!</h3>
                <p>Tente novamente ou confirme os dados do paciente para realizar uma nova busca.</p>

              </div>
            ) : null
            }
          </div>
        )}
      </div>

      { modal && 
        <Modal
            isOpen={modal}
            onRequestClose={closeModal}
            style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '8px',
              width: '615px',
            },
          }}
        > 
        </Modal>  
      }
      </ReactCSSTransitionGroup>
    </div>
	)
}

export default Search;
