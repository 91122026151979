import BotyHistoryContainer from '../../../Container/Dashboard/BotHistory';

const BotHistory = () => {

	return (
		<BotyHistoryContainer />
	)
}

export default BotHistory;
