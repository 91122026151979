import React from 'react';
import styles from './style.module.css';

const ResponseRadioButtons = ({ selectedResponse, groupName }) => {
  const options = ["nenhuma", "leve", "moderada", "grave", "muito grave"];

  return (
    <div className={styles.radioGroup}>
      {options.map(option => (
        <label key={option} className={styles.radioLabel}>
          <input
            type="radio"
            name={groupName}
            value={option}
            checked={selectedResponse?.toLowerCase() === option?.toLowerCase()}
            onChange={() => {}}
            disabled={true}
          />
          {option}
        </label>
      ))}
    </div>
  );
};

export default ResponseRadioButtons;
