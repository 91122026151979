import React, { useState } from 'react';
import styles from './style.module.css';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { useEffect } from 'react';
import LoadingOverlay from '../../../Components/LoadingOverlay';
import { Button } from '../../../Components';
import Modal from 'react-modal';
import ReportFilterTool from '../../../Components/ReportFilterTool';
import ModalStatus from '../../../Components/ReportFilterTool/ModalStatus';

import HealthHeader from '../../../Components/HealthHeader';
import HealthCards from '../../../Components/HealthCard';
import HealthPatientStatsTable from '../../../Components/HealthPatientStatsTable';
import { getStatistics } from '../../../services/kollectaHealthCore';

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalFeedback, setModalFeedback] = useState(false);
  const [statisticsData, setStatisticsData] = useState(null);

  const closeFiltersModal = () => {
    setModal(false);
  }

  const closeStatusModal = () => {
    setModal(false);
    setModalFeedback(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStatistics();
        setStatisticsData(data?.data);
      } catch (error) {
        console.error('Erro ao buscar os dados de estatísticas:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [statisticsData]);

  const data = [
    { value: statisticsData?.data?.totalCustomers, label: 'PACIENTES CADASTRADOS' },
    { value: statisticsData?.data?.percentageWithBotHistory, label: 'ÍNDICE DE ADESÃO AO ESTUDO' }
  ];

  const headers = ['PACIENTES', 'CONTAGEM', 'ÍNDICE'];

  const tableData = [
    {
      description: 'Pacientes que apresentaram sintomas leves',
      count: statisticsData?.data?.responseLevels?.leve?.count,
      percentage: statisticsData?.data?.responseLevels?.leve?.percentage,
      color: '#266400'
    },
    {
      description: 'Pacientes que apresentaram sintomas moderados',
      count: statisticsData?.data?.responseLevels?.moderada?.count,
      percentage: statisticsData?.data?.responseLevels?.moderada?.percentage,
      color: '#786C00'
    },
    {
      description: 'Pacientes que apresentaram sintomas graves',
      count: statisticsData?.data?.responseLevels?.grave?.count,
      percentage: statisticsData?.data?.responseLevels?.grave?.percentage,
      color: '#B41411'
    },
  ];

  const tableDataWithIcons = [
    {
      title: 'PACIENTES ENCAMINHADOS AO P.S',
      items: [
        {
          label: 'CONTAGEM',
          value: statisticsData?.data?.referredPS.count,
        },
        {
          label: 'ÍNDICE',
          value: statisticsData?.data?.referredPS.percentage,
        }
      ]
    }
  ];

  const totalData = 
  {
    description: 'Total de pacientes que apresentaram algum sintoma',
    count: statisticsData?.data?.totalWithAnySymptom.count,
    percentage: statisticsData?.data?.totalWithAnySymptom.percentage,
    color: '#FFFFFF'
  }

	return (
    <div>
      <LoadingOverlay isVisible={loading}/>

      <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>

        <HealthHeader title="Dashboard de Pacientes"/>

      <div className={styles.container}>
        <div className={styles.buttonContainer}>
          <Button onClick={() => setModal(true)}> Exportar Relatório </Button>
        </div>
        <HealthCards items={data} />
        <HealthPatientStatsTable header={headers} data={tableData} totalData={totalData} />
        <HealthCards items={tableDataWithIcons} type="icon" />
      </div>

      { !modalFeedback && 
        <Modal
            isOpen={modal}
            onRequestClose={closeFiltersModal}
            style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '8px',
              width: '615px',
            },
          }}
        > 
          <div className={styles.modalContainer}>
            <ReportFilterTool closeModal={closeFiltersModal} setFeedback={setModalFeedback} />
          </div>
        </Modal>  
      }

      <ModalStatus type={modalFeedback} onClose={closeStatusModal}  />

      </ReactCSSTransitionGroup>
    </div>
	)
}

export default Home;
